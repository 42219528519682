import { Component, Input, OnInit } from "@angular/core";
import { FileUploader } from "ng2-file-upload";
import { environment } from "src/environments/environment";
import { FolderModel } from "src/app/shared/models/global/folder.model";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToasterService } from "angular2-toaster";
import { AuthenticationService } from "src/app/core/authentication/authentication.service";
import { FileService } from "src/app/core/global/file/file.service";
import { FolderService } from "src/app/core/global/folder/folder.service";
import { appToaster } from "src/app/configs/app-toaster.config";
import { interval } from "rxjs";

@Component({
  selector: "app-popup-upload-file-aws",
  templateUrl: "./popup-upload-file-aws.component.html",
  styleUrls: ["./popup-upload-file-aws.component.css"],
})
export class PopupUploadFileAWSComponent implements OnInit {
  uploader: FileUploader;
  @Input() title: string;
  @Input() queueLimit: number = 1;
  @Input() allowedFileType: any;
  @Input() allowedMimeType: any;
  @Input() folder_path: string;
  @Input() link_aws: boolean = false;
  @Input() showFile: boolean = false;
  url: string = environment.host_media + "upload";
  folder_id: number;
  parameter: any;
  @Input() view_upload: boolean = false;

  currentPage: number = 1;
  startShow: number = 0;
  endShow: number = 0;
  perPage: number = 10;
  total: number = 0;

  files: any;
  folder: FolderModel;

  loading: boolean = false;

  public hasBaseDropZoneOver: boolean = false;
  fileName: string = "";
  path: string = "";

  constructor(
    public activeModal: NgbActiveModal,
    private toasterService: ToasterService,
    public authenticationService: AuthenticationService,
    private fileService: FileService,
    private folderService: FolderService
  ) {}

  ngOnInit() {
    +this.setOptions();
    this.uploader.onBeforeUploadItem = (item: any) => {
      item.withCredentials = false;
    };

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = JSON.parse(response);
      if (response.status == "success") {
        this.path = response.data.path;
        if (this.link_aws) {
          this.path = response.data.link;
        }
        this.toasterService.pop("success", appToaster.successHead, response.message);
      } else {
        this.toasterService.pop("error", appToaster.errorHead, response.message);
      }
    };
  }

  save(): void {
    this.activeModal.close(this.path);
  }

  close(): void {
    this.activeModal.close();
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  setOptions() {
    this.uploader = new FileUploader({ queueLimit: this.queueLimit });
    let options = {
      url: this.url,
      allowedFileType: this.allowedFileType,
      allowedMimeType: this.allowedMimeType,
      headers: [{ name: "token", value: this.authenticationService.getToken() }],
      additionalParameter: { description: "", folder_path: this.folder_path },
    };
    this.uploader.setOptions(options);
    this.getFiles();
  }

  getFiles() {
    if(!this.showFile) {
      return false;
    }
    this.loading = true;

    let subscribeListFiles = this.fileService
      .getListFiles(this.folder_path, this.currentPage, this.perPage)
      .subscribe(
        (res) => {
          this.loading = false;
          if (res.status === "success") {
            this.folder = res.data.folder;
            this.files = res.data.files;
            this.total = res.data.count_files;
          } else {
            this.folder = new FolderModel();
            this.files = [];
            this.total = 0;
            this.toasterService.pop("error", appToaster.errorHead, res.message);
          }
        },
        (error) => {
          this.loading = false;
          this.folder = new FolderModel();
          this.files = [];
          this.total = 0;
        }
      );

    const observableTotal = interval();

    let subscribeTotal = observableTotal.subscribe(() => {
      this.startShow = (this.currentPage - 1) * this.perPage + 1;
      this.endShow = this.currentPage * this.perPage;
      if (this.total < this.endShow) {
        this.endShow = this.total;
      }
    });

    subscribeListFiles.add(subscribeTotal);
  }

  changePage(event) {
    this.currentPage = event;
    this.getFiles();
  }

  selectedFile(path, link) {
    this.path = path;
    if (this.link_aws) {
      this.path = link;
    }
  }

  fileSize(size) {
    if (isNaN(size)) size = 0;
    if (size < 1024) return size + " Bytes";

    size /= 1024;
    if (size < 1024) return size.toFixed(2) + " Kb";

    size /= 1024;
    if (size < 1024) return size.toFixed(2) + " Mb";

    size /= 1024;
    if (size < 1024) return size.toFixed(2) + " Gb";

    size /= 1024;
    return size.toFixed(2) + " Tb";
  }

  copyMessage(val: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.toasterService.pop("success", appToaster.successHead, "Đã sao chép link vào Clipboard");
  }
}
